<template>
  <div class="tabpane_content">
    <div class="content_header">
      <el-input
        style="width:300px;margin-right: 10px;"
        size="small"
        placeholder="请输入服务名称或机构名称"
        suffix-icon="el-icon-search"
        v-model="searchCondition.keywords"
        @keyup.native.enter="onSearch">
      </el-input>
      <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="searchCondition.technicalId"
        placeholder="请选择技术领域"
        clearable
        @change="onSearch">
        <el-option
          v-for="item in technicalList"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </div>
    <div class="content_main">
      <el-table
        v-loading="loading"
        :header-cell-style="headerCellStyle"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="serviceTypeName"
          label="服务类型">
        </el-table-column>
        <el-table-column
          prop="title"
          label="服务名称">
        </el-table-column>
        <el-table-column
          width="150px"
          prop="fee"
          label="价格">
        </el-table-column>
        <el-table-column
          width="150px"
          prop="orgName"
          label="机构名称">
        </el-table-column>
        <el-table-column
          width="150px"
          prop="username"
          label="联系人">
        </el-table-column>
        <el-table-column
          prop="createdAt"
          label="申请时间">
        </el-table-column>
        <el-table-column
          width="100px"
          label="操作">
          <template slot-scope='scoped'>
            <span
              class="text_color_active tw-cursor-pointer"
              @click.stop="rowClick(scoped.row)">
              审核
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content_footer">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'

export default {
  data () {
    return {
      loading: false,
      // 搜索对象
      searchCondition: {
        keywords: '',
        logicStatus: globalVar.SERVICE_STATUS_0,
        technicalId: '' // 技术领域筛选项
      },
      tableData: [],
      // 分页对象
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    // 表格头
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    getTableData (pageNum) {
      this.loading = true
      api.getServiceList({
        pageNum,
        pageSize: this.pagination.pageSize,
        ...this.searchCondition
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 5e79b1151fb70242bd5d3f85b3ef1f90
    // 2143ad0137dedc22fec89feb0d501e2c
    // 页码变化
    pageNumChange (pageNum) {
      this.getTableData(pageNum)
    },
    onSearch () {
      this.getTableData(1)
    },
    // 分页序号
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    // 查看详情
    rowClick (row) {
      const { href } = this.$router.resolve({
        path: `/resource-require/service/detail/${row.id}`
      })
      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .tabpane_content {
    padding: 15px 25px;
    .content_header {
      display: flex;
      margin-bottom: 20px;
    }
    .content_main {
      margin-bottom: 20px;
    }
    .content_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
  }
</style>
