<!--
  服务资源表
    + 后台下架服务如果这个服务在机构服务链中，则后台提示“无法下架”（王童）
 -->
<template>
  <div class="tabpane_content">
    <div class="content_header">
      <el-button type="primary" size="small" style="margin-right: 10px;" @click="createService">发布服务</el-button>
      <!-- 服务名称 -->
      <el-input
        style="width:300px;margin-right: 10px;"
        size="small"
        placeholder="请输入服务名称或机构名称"
        suffix-icon="el-icon-search"
        v-model="searchCondition.keywords"
        @keyup.native.enter="onSearch">
      </el-input>
      <!-- 请选择服务状态 -->
      <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="searchCondition.logicStatus"
        :clearable="true"
        placeholder="请选择服务状态"
        @change="onSearch">
        <el-option
          v-for="item in logicStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <!-- 服务类型 选择的是一级服务类型 -->
      <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="searchCondition.serviceTypeId"
        :clearable="true"
        placeholder="请选择服务类型"
        @change="onSearch">
        <el-option
          v-for="item in serviceTypeOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button
        type="primary"
        plain
        size="small"
        :loading="exportBtnLoading"
        @click="onExport">
        导出
      </el-button>
    </div>
    <div class="content_main">
      <el-table
        v-loading="loading"
        :header-cell-style="headerCellStyle"
        :data="tableData"
        style="width: 100%"
        @row-click="onDetail">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="parentServiceTypeName"
          label="服务类型">
        </el-table-column>
        <el-table-column
          prop="title"
          label="服务名称">
        </el-table-column>
        <el-table-column
          prop="username"
          label="用户名">
        </el-table-column>
        <el-table-column
          prop="orgName"
          label="机构名称">
        </el-table-column>
        <el-table-column
          prop="fee"
          label="价格">
        </el-table-column>
        <el-table-column
          prop="reviewerName"
          label="审核人">
        </el-table-column>
        <el-table-column
          prop="operatorAt"
          label="审核时间">
        </el-table-column>
        <el-table-column
          width="100px"
          label="操作">
          <!-- 只有已通过才能编辑和下架 -->
          <template
            v-if="scoped.row.logicStatus === globalVar.SERVICE_STATUS_1"
            slot-scope='scoped'>
            <span
              class="text_color_active tw-cursor-pointer tw-mr-5px"
              @click.stop="onEdit(scoped.row)">
              编辑
            </span>
            <span
              class="text_color_danger tw-cursor-pointer"
              @click.stop="onCancel(scoped.row)">
              下架
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content_footer">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
import { renderExcel } from '@/utils/excel.export.js'
export default {
  data () {
    return {
      loading: false,
      exportBtnLoading: false,
      // 搜索对象
      searchCondition: {
        keywords: '',
        logicStatus: '',
        serviceTypeId: ''
      },
      logicStatusOptions: [
        { label: '已通过', value: globalVar.SERVICE_STATUS_1 },
        { label: '已驳回', value: globalVar.SERVICE_STATUS_2 },
        { label: '已下架', value: globalVar.SERVICE_STATUS_3 }
      ],
      serviceTypeOptions: [],
      tableData: [],
      // 分页对象
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    // 表格头
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    },
    globalVar () {
      return globalVar
    }
  },
  created () {
    this.getServiceTypeOptions()
    this.getTableData()
  },
  methods: {
    // 获取服务类型
    getServiceTypeOptions () {
      api.getServiceTypeOptions().then(res => {
        if (res.data.code === 0) {
          this.serviceTypeOptions = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 获取表格数据
    getTableData (pageNum) {
      this.loading = true
      const searchVar = this.getSearchVar()
      api.getServiceList({
        ...searchVar,
        pageNum,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 进入详情
    onDetail (row) {
      const { href } = this.$router.resolve({
        path: `/resource-require/service/detail/${row.id}`
      })
      window.open(href, '_blank')
    },
    // 点击编辑 进入编辑界面
    onEdit (row) {
      window.open(`/resource-require/service/edit/${row.id}`)
    },
    // 下架服务
    onCancel (item) {
      this.$msgbox({
        title: '撤销服务',
        message: '确定要撤销该服务？',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.cancelService(item, instance, done)
          } else {
            done()
          }
        }
      })
    },
    cancelService (item, instance, done) {
      instance.confirmButtonLoading = true

      api.editServiceStatus(item.id, {
        logicStatus: globalVar.SERVICE_STATUS_3
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.getTableData(this.pagination.pageNum)
        } else {
          this.$message.error(res.data.message)
        }
        done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        instance.confirmButtonLoading = false
      })
    },
    // 导出服务为excel
    onExport () {
      // 如果有选择状态的话 用选择的，否则 就传[已通过，已驳回，已取消]
      const searchVar = this.getSearchVar()
      this.exportBtnLoading = true
      api.exportService({
        ...searchVar
      }).then(res => {
        if (res.data.code === 0) {
          console.log('服务导出', res.data.data)
          const { list, header } = res.data.data
          renderExcel(list, header, '服务数据')
        } else {
          this.$message.error(res.data.message)
        }
        this.exportBtnLoading = false
      }).catch(err => {
        this.exportBtnLoading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 创建服务
    createService () {
      window.open('/resource-require/service/create', '_blank')
    },
    onSearch () {
      this.getTableData(1)
    },
    // 获取搜索条件
    getSearchVar () {
      const logicStatus = this.searchCondition.logicStatus || `${globalVar.SERVICE_STATUS_1},${globalVar.SERVICE_STATUS_2},${globalVar.SERVICE_STATUS_3}`
      const keywords = this.searchCondition.keywords
      const serviceTypeId = this.searchCondition.serviceTypeId
      return {
        logicStatus,
        keywords,
        serviceTypeId
      }
    },
    // 序号
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    // 页码变化
    pageNumChange (pageNum) {
      this.getTableData(pageNum)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tabpane_content {
    padding: 15px 25px;
    .content_header {
      display: flex;
      margin-bottom: 20px;
    }
    .content_main {
      margin-bottom: 20px;
    }
    .content_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
  }
</style>
