<template>
  <div class="service">
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane label="待审核" lazy name="1">
        <review-table></review-table>
      </el-tab-pane>
      <el-tab-pane label="已审核" lazy name="2">
        <resource-table></resource-table>
      </el-tab-pane>
      <el-tab-pane label="我的审核" lazy name="3">
        <myreview-table></myreview-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import resourceTable from './components/resource-table'
import reviewTable from './components/review-table'
import myreviewTable from './components/myreview-table.vue'

export default {
  components: {
    resourceTable,
    reviewTable,
    myreviewTable
  },
  data () {
    return {
      activeTab: '1'
    }
  }
}
</script>

<style lang="scss" scoped>
  .service {
    width: 980px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }
</style>
